<template>
  <b-container class="container-rda">
    <b-row>
      <b-col>
        <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <h2 class="purple bold">Editar banner</h2>
      </b-col>
    </b-row>

    <b-row class="teacher-profile mt-50">
      <b-col>
        <b-row>
          <b-col>
            <b-form-group class="required" description>
              <label for="label">Disponível até</label>
              <b-form-input
                v-model="banner.available_until"
                type="date"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group description>
              <label for="label">Url</label>
              <b-form-input v-model="banner.url" type="text"></b-form-input>
              <a
                target="_blank"
                v-if="banner.url"
                :href="banner.url"
                style="text-decoration: underline; color: #b23066"
                >Testar Link</a
              >
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group class="required" description>
              <label for="label">Descrição</label>
              <b-form-textarea
                v-model="banner.description"
                type="textarea"
                placeholder
                required
              ></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
        <targets :data="banner" />
      </b-col>
    </b-row>

    <b-row align-v="center" class="mt-5">
      <b-col class="text-right">
        <b-button
          class="large blue outline"
          :to="{
            name: 'banners',
          }"
          >Voltar</b-button
        >
        <b-button :disabled="!formFilled" @click="submit" class="large blue"
          >Atualizar banner</b-button
        >
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import httpRda from "../../../http-rda.js";
import Targets from "../../../components/Targets.vue";

export default {
  name: "updateBanner",
  components: { Targets },

  data() {
    return {
      formFilled: false,
      banner: null,
      attachment: null,
      file: null,

      breadcrumb: [
        {
          text: "Administração",
          href: "/administracao",
        },
        {
          text: "Banneros",
          href: "/administracao/banners",
        },
        {
          text: "Editar banner",
          href: "updateBanner",
          active: true,
        },
      ],
    };
  },

  computed: {
    id() {
      return this.$route.params.id;
    },
  },

  watch: {
    banner: {
      handler: function (newBanner) {
        this.updateFormFilled(newBanner, this.targets);
      },
      deep: true,
    },
  },

  methods: {
    updateFormFilled(banner) {
      this.formFilled = banner.description && banner.available_until;
    },

    getBanner() {
      httpRda.get(`banners/${this.id}`).then((response) => {
        this.banner = response.data.banner;
      });
    },

    async setFile(file) {
      this.file = file;
    },

    async submitFile(id) {
      if (this.file) {
        const bodyFormData = new FormData();
        bodyFormData.append("fileName", this.file.name);
        bodyFormData.append("fileType", "image_url");
        bodyFormData.append("file", this.file);

        try {
          await httpRda
            .patch(`/banners/${id}`, bodyFormData, {
              headers: { "Content-Type": "multipart/form-data" },
            })
            .then((response) => (this.file.url = response.data.url));
        } catch (error) {
          alert(error);
        }

        this.getBanner();
      }
    },

    async removeImage() {
      try {
        const { value: accept } = await this.$swal.fire({
          title: "Tem certeza?",
          text: "Você não poderá reverter isso!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Sim, remover!",
          cancelButtonText: "Não, cancelar!",
          reverseButtons: true,
        });

        if (!accept) {
          return;
        }

        await httpRda
          .patch(`/banners/${this.id}`, { image_url: null })
          .then(() => (this.file = null));
      } catch (error) {
        alert(error);
      }

      this.getBanner();
    },

    async submit() {
      // eslint-disable-next-line
      const { targets, ...data } = this.banner;

      try {
        await httpRda.patch(`/banners/${this.id}`, data).then(async () => {
          if (this.file) {
            await this.submitFile(this.id);
          }
        });
        this.$router.push({ name: "banners" });
      } catch (error) {
        alert(error);
      }
    },
  },

  created() {
    this.getBanner();
  },
};
</script>

<style lang="scss">
.teacher-profile {
  .form-group {
    &.required {
      &:after {
        right: 0;
      }
    }
  }
}

.title-collapse {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  h5 {
    flex: 1;
  }

  .btn {
    background: transparent;
    border: 0;

    svg {
      font-size: 16px;
      fill: red;
    }
  }
}

.table-students {
  width: 100%;

  td,
  th {
    font-size: 18px;
    width: 50%;
  }

  th {
    padding-bottom: 20px;
  }

  td {
    padding: 20px 0;
  }

  tr + tr {
    border-bottom: 1px solid $grey5;
  }
}

.student-responsible {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;

  span {
    background-color: #efefef;
    padding: 4px 8px;
    border-radius: 4px;
    box-sizing: border-box;
  }
}

.wrap-tags {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;

  span {
    background-color: #efefef;
    padding: 4px 8px;
    border-radius: 4px;
    box-sizing: border-box;
  }
}

.wrap-button {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 6px;
}

.tag {
  background-color: #68c5b7;
  border-radius: 5px;
  color: #ffffff;
  font-weight: 700;
  line-height: 28px;
  margin: 3px 12px 3px 0;
  padding: 0 3px;
  padding-left: 8px;
  padding-right: 8px;
  text-align: center;
  vertical-align: middle;
  max-width: 100%;
}

.container-rda .multiselect__tag-icon:after {
  color: white;
}

input.isInvalid {
  border-color: red;
}
</style>
